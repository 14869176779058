import React, { useContext } from "react";
import Logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import MobileMenu from "../MobileMenu";
import { Context } from "../../context";

const Header3 = (props) => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const { setOpenSignUpPopUp, isLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleOpenSignUpPopUp = () => {
    if (!isLoggedIn) setOpenSignUpPopUp(true);
    else navigate("/myAccount");
  };

  return (
    <div className="middle-header s2">
      <div className="header-style-3">
        <div className="header-style-3">
          <div className="header-content">
            {/* <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-4 col-4 d-flex align-items-center">
                <div className="logoMsajid">
                  <Link onClick={ClickHandler} to="/home" title="">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 d-lg-block d-none">
                <nav>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/home" title="">
                        الصفحة الرئيسية
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/about" title="">
                        نبذة عن المؤسسة
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/event" title="">
                        شارك معنا
                        <span className="arrow">&#9662;</span>
                      </Link>
                      <ul>
                        <li>
                          <Link onClick={ClickHandler} to="/event" title="">
                            تبرع مادي
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/InkindDonationPage"
                            title=""
                          >
                            تبرع عيني
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link onClick={ClickHandler} to="/contact" title="">
                        تواصل معنا
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-6">
                <div className="contact-info">
                  <Link className="FinancialDonation" to="/InkindDonationPage">
                    تبرع عيني
                  </Link>
                  <Link className="In-kindDonationBtn" to="/event">
                    تبرع مادي
                  </Link>
                </div>
              </div>
              <div className="col-md-1 col-sm-2 col-2">
                <MobileMenu />
              </div>
            </div> */}
            <div className="responsiveNavBar">
              <div className="d-flex align-items-center">
                <div className="logoMsajid">
                  <Link onClick={ClickHandler} to="/home" title="">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="d-lg-block d-none">
                <nav>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/home" title="">
                        الصفحة الرئيسية
                      </Link>
                    </li>
                    <li>
                      <a onClick={ClickHandler} href="/home" >نبذة عن المؤسسة</a>

                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/event" title="">
                        شارك معنا
                        <span className="arrow">&#9662;</span>
                      </Link>
                      <ul>
                        <li>
                          <Link onClick={ClickHandler} to="/event" title="">
                            تبرع مادي
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/InkindDonationPage"
                            title=""
                          >
                            تبرع عيني
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link onClick={ClickHandler} to="/contact" title="">
                        تواصل معنا
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="d-lg-block d-none">
                <div className="contact-info">
                  <Link className="FinancialDonation" to="/InkindDonationPage">
                    تبرع عيني
                  </Link>
                  <Link className="In-kindDonationBtn" to="/event">
                    تبرع مادي
                  </Link>
                </div>
              </div>
              <div>
                <MobileMenu />
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header3;
