import React, { useState } from 'react';
import { CreateInquiriess } from "../../API";
import { toast } from 'react-toastify';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [notes, setNotes] = useState('');

    const submitHandler = () => {
        // Validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^(01[0125])[0-9]{8}$/;

        if (!name || !lastname || !email || !phone || !notes) {
            toast.error('يرجى ملء جميع الحقول');
            return;
        }

        if (!emailRegex.test(email)) {
            toast.error('البريد الإلكتروني غير صالح');
            return;
        }

        if (!phoneRegex.test(phone)) {
            toast.error('رقم الهاتف غير صالح');
            return;
        }

        // If all validations pass, proceed with creating the inquiry
        // CreateInquiriess({
        //     "@FirstName": name,
        //     "@LastName": lastname,
        //     "@Phone": phone,
        //     "@Email": email,
        //     "@Content": notes
        // }).then((response) => {
        //     toast.success('تم إرسال الاستفسار بنجاح');
        //     setName('');
        //     setLastname('');
        //     setEmail('');
        //     setPhone('');
        //     setNotes('');
        // }).catch((error) => {
        //     console.log(error);
        // });
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                    <div className="form-field">
                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" name="name" placeholder="الأسم الأول" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                    <div className="form-field">
                        <input value={lastname} onChange={(e) => setLastname(e.target.value)} type="text" name="lastname" placeholder="الأسم الأخير" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                    <div className="form-field">
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" placeholder="البريد الألكترونى" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                    <div className="form-field">
                        <input value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" name="phone" placeholder="رقم التليفون" />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-field">
                        <textarea value={notes} onChange={(e) => setNotes(e.target.value)} name="notes" placeholder="الرسالة"></textarea>
                    </div>
                </div>
                <div className="col-lg-12 d-flex">
                    <div>
                        <button onClick={submitHandler} className="theme-btn">ارسل الأن</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm;
