import React from 'react';
import './style.css'
import { useNavigate } from "react-router-dom";

const ServicesCard = ({
    cardImg,
    cardContent,
    cardTitle,
    path

}) => {
    const navigate = useNavigate()
    return (
        <div className="masajidServicesCardMainContainer" onClick={() => navigate(path)} >
            <img src={cardImg} alt="" />
            <div className='serviceCardFooter'>
                <span>{cardTitle}</span>
                <div>{cardContent}</div>
            </div>

        </div>
    );
};

export default ServicesCard;
