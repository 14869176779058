import axios from "axios";
import { toast } from "react-toastify";
const API_URL =
  "https://api.shopimix.com/api/general";
const AUTH =
  "https://api.shopimix.com/api/dashboard/token";

const CreateRequest = (procName, params, API, tablesNames, isFormData) => {
  return new Promise((resolve, reject) => {
    const requestConfig = {
      headers: {},
    };
    if (!procName.includes("Unauthorized")) {
      requestConfig.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }

    axios.post(
      API || API_URL,
      isFormData
        ? params
        : {
          ConnectionStringName: "Masajed",
          StoredProcedureName: procName,
          SpParams: params,
          TablesNames: tablesNames,
        },
      requestConfig
    )
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error);
        if (
          error.response?.data["error-message"] == "30" ||
          error.response?.data["error-message"] == "20"
        ) {
          localStorage.clear();
          window.location.reload();
        }
        toast.error(error.response?.data?.Message || error);
        reject(error);
      });
  });
};






export const Login = (params) => {
  return CreateRequest(
    "web.Login",
    {
      ...params,
    },
    AUTH
  );
};

export const SignUp = (params) => {
  return CreateRequest(
    "Unauthorized.SignUp",
    {
      ...params,
    },
    AUTH
  );
};

export const InsertVolunteeringRequest = (params) => {
  return CreateRequest(
    "Unauthorized.InsertVolunteeringRequest",
    {
      ...params,
    }
  );
};

export const InsertDeceasedDonationTransactions = (params) => {
  return CreateRequest(
    "Unauthorized.InsertDeceasedDonationTransactions",
    {
      ...params,
    }
  );
};

export const InsertGiftDonation = (params) => {
  return CreateRequest(
    "Unauthorized.InsertGiftDonation",
    {
      ...params,
    }
  );
};

export const CreateShareDonations = (params) => {
  return CreateRequest(
    "Unauthorized.CreateShareDonations",
    {
      ...params,
    }
  );
};





export const GenerateOTP = (params) => {
  return CreateRequest(
    "Unauthorized.GenerateOTP",
    {
      ...params,
    }
  );
};

export const CheckOTP = (params) => {
  return CreateRequest(
    "Unauthorized.CheckOTP",
    {
      ...params,
    }
  );
};

export const ChangePassword = (params) => {
  return CreateRequest(
    "Unauthorized.ChangePassword", {
    ...params,
  }
  );
};

export const GetMasajedLibrary = (params) => {
  return CreateRequest(
    "Unauthorized.GetMasajedLibrary", {
    ...params,
  }
  );
};

export const CreateDonateTransaction = (params) => {
  return CreateRequest(
    "Unauthorized.CreateDonateTransaction", {
    ...params,
  }
  );
};

export const GetDeceasedInfo = (params) => {
  return CreateRequest(
    "Unauthorized.GetDeceasedInfo", {
    ...params,
  }
  );
};

export const GetDeceasedRelatives = (params) => {
  return CreateRequest(
    "Unauthorized.GetDeceasedRelatives", {
    ...params,
  }
  );
};

export const GetDonateDeceasedHistory = (params) => {
  return CreateRequest(
    "Unauthorized.GetDonateDeceasedHistory", {
    ...params,
  }
  );
};

export const GetDonationMaterials = (params) => {
  return CreateRequest(
    "Unauthorized.GetDonationMaterials", {
    ...params,
  }
  );
};


export const GetUserAddress = (params) => {
  return CreateRequest(
    "Unauthorized.GetUserAddress", {
    ...params,
  }
  );
};

export const DeleteUserAddress = (params) => {
  return CreateRequest(
    "Unauthorized.DeleteUserAddress", {
    ...params,
  }
  );
};


export const CreateInkindDonations = (params) => {
  return CreateRequest(
    "Unauthorized.CreateInkindDonations", {
    ...params,
  }
  );
};

export const CreateShippingRequest = (params) => {
  return CreateRequest(
    "Unauthorized.CreateShippingRequest", {
    ...params,
  }
  );
};


export const GetAllInkindDonations = (params) => {
  return CreateRequest(
    "Unauthorized.GetAllInkindDonations", {
    ...params,
  }
  )
}


export const GetVolunteeringRequest = (params) => {
  return CreateRequest(
    "Unauthorized.GetVolunteeringRequest", {
    ...params,
  }
  )
}

export const ResendVolunteeringRequest = (params) => {
  return CreateRequest(
    "Unauthorized.ResendVolunteeringRequest", {
    ...params,
  }
  )
}


export const GetAllVolunteeringActivities = (params) => {
  return CreateRequest(
    "Unauthorized.GetAllVolunteeringActivities", {
    ...params,
  }
  )
}


export const GetDonateDeceasedDetails = (params) => {
  return CreateRequest(
    "Unauthorized.GetDonateDeceasedDetails", {
    ...params,
  }
  );
};

export const GetDonateGiftDetails = (params) => {
  return CreateRequest(
    "Unauthorized.GetDonateGiftDetails", {
    ...params,
  }
  );
};

export const GetDonationsTotalNumbers = (params) => {
  return CreateRequest(
    "Unauthorized.GetDonationsTotalNumbers", {
    ...params,
  }
  );
};

export const GetMasajedCampaigns = (params) => {
  return CreateRequest(
    "Unauthorized.GetMasajedCampaigns", {
    ...params,
  }
  );
};


export const CreateInquiriess = (params) => {
  return CreateRequest(
    "Unauthorized.CreateInquiries", {
    ...params,
  }
  );
};

export const AddNewAddress = (params) => {
  return CreateRequest(
    "Unauthorized.AddNewAddress", {
    ...params,
  }
  );
};

export const CreateMasajedCampaignsDonation = (params) => {
  return CreateRequest(
    "Unauthorized.CreateMasajedCampaignsDonation", {
    ...params,
  }
  );
};

export const GetAccountSavedCards = (params) => {
  return CreateRequest(
    "Unauthorized.GetAccountSavedCards", {
    ...params,
  }
  );
};

export const DeleteSavedCards = (params) => {
  return CreateRequest(
    "Unauthorized.DeleteSavedCards", {
    ...params,
  }
  );
};



export const GetDonateShareDetails = (params) => {
  return CreateRequest(
    "Unauthorized.GetDonateShareDetails", {
    ...params,
  }
  );
};

export const GetDonationsHistory = (params) => {
  return CreateRequest(
    "Unauthorized.GetDonationsHistory", {
    ...params,
  }
  );
};

export const UpdateAccountInfo = (params) => {
  return CreateRequest(
    "Unauthorized.UpdateAccountInfo", {
    ...params,
  }
  );
};

export const ChangeAccountPassword = (params) => {
  return CreateRequest(
    "Unauthorized.ChangeAccountPassword", {
    ...params,
  }
  );
};

export const GetAccountInfo = (params) => {
  return CreateRequest(
    "Unauthorized.GetAccountInfo", {
    ...params,
  }
  );
};

export const InsertDeceasedDonation = (params, tablesNames) => {
  return CreateRequest("Unauthorized.InsertDeceasedDonation", {
    ...params,
    "@userID": localStorage.getItem("userId"),
  },
    null, tablesNames
  );
};

// export const UploadFile = (formData) => {
//   return CreateRequest(null,

//     formData,

//     UPLOAD_EXCEL_FILE, null, true
//   );
// };
// export const UploadFileOneToOne = (formData) => {
//   return CreateRequest(null,

//     formData,

//     UPLOAD_EXCEL_OneToOne, null, true
//   );
// };
// export const GetUser = (params) => {
//   return CreateRequest("[dbo].GetUser", {
//     ...params,
//   });
// };