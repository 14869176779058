import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import ServicesCard from "../servicesCard";
import card1Img from "../../images/ElectronicWallet.png";
import card2Img from "../../images/BankCard.png";
import card3Img from "../../images/BankTransfer.png";

const MasajidServices = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      className={`MasajidServices-section section-padding ${props.teamClas}`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col col-lg-6">
            <div className="section-title text-center">
              <h2>تبرع مادي</h2>
              <span>
                نعمل على توفير طرق تبرع كثيرة للحفاظ على سهولة التبرع المادى
                للمتبرعين
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div className="masajidServices-grids clearfix">
              <ServicesCard
                cardImg={card1Img}
                cardTitle="محفظة الكترونية"
                cardContent={
                  "يمكنك التبرع من خلال المحفظة البنكية الخاصة بك, فودافون كاش, اتصالات كاش, الخ.."
                }
                path={"/ElectronicWalletDonatePage"}
              />

              <ServicesCard
                cardImg={card2Img}
                cardTitle="بطاقة بنكية"
                cardContent={
                  "يمكنك التبرع بأى من البطاقات البنكية فيزا, ماستر كارد, الخ.."
                }
                path={"/BankCardDonatePage"}
              />

              <ServicesCard
                cardImg={card3Img}
                cardTitle="تحويل بنكى"
                cardContent={
                  "يمكنك التبرع من خلال التحويلات البنكية بكل سهولة عن طريق الحسابات البنكية الخاصة بنا"
                }
                path={"/BankTransferDonatePage"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MasajidServices;
