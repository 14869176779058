import React, { Fragment, useEffect } from "react";
import Navbar3 from "../../components/Navbar3";
import PageTitle from "../../components/pagetitle";
import About from "../../components/about";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Newsletter from "../../components/Newsletter";
import img from "../../images/BankCardCover.png";
import BankCardDonate from "../../components/BankCardDonate";

const BankCardDonatePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Navbar3 />
      <PageTitle img={img} pageTitle={"بطاقة بنكية"} pagesub={"بطاقة بنكية"} />
      <BankCardDonate />
      <Newsletter />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BankCardDonatePage;
