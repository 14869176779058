import React, { Fragment } from "react";
import Navbar3 from "../../components/Navbar3";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Newsletter from "../../components/Newsletter";
import img from "../../images/joinUs.png";
import MasajidServices from "../../components/masajidServices";

const EventPage = () => {
  return (
    <Fragment>
      <Navbar3 />
      <PageTitle img={img} pageTitle={"تبرع مادي"} pagesub={"تبرع مادي"} />
      <MasajidServices />
      <Newsletter />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default EventPage;
