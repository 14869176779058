import React from 'react';
import AllRoute from '../router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Provider from "../../context";
import './App.css';


const App = () => {

  return (
    <Provider>
      <div className="App" id='scrool'>
        <AllRoute />
        <ToastContainer />
      </div>
    </Provider>

  );
}

export default App;
