import React, { useEffect, useState } from "react";
import "./style.css";


const InkindDonation = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [donateMaterial, setDonateMaterial] = useState("");

  const handleSubmit = () => { };

  return (
    <div className="Inkind-Donation">
      <div className="donation-heading">تبرع عيني</div>
      <div className="titleUnderLine underLineTitle"></div>
      <div className="Inkind-DonationContent">
        <span>
          بامكانك مساعدتنا عن طريق التبرع بالأشياء, فقط املأ الفورم وسيتواصل معك
          احد من العاملين باللجنة لتحديد موعد
        </span>
      </div>
      <div className="donatecontainer">
        <div className="content">
          <div className="donation-content">
            <div className="donateForm">
              <input
                className="other"
                type="text"
                placeholder="اسم المتبرع"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                className="other"
                type="text"
                placeholder="رقم الهاتف"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <input
                className="other"
                type="text"
                placeholder="مادة التبرع"
                value={donateMaterial}
                onChange={(e) => setDonateMaterial(e.target.value)}
              />
              <input
                className="other"
                type="text"
                placeholder="العنوان"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <button className="donate-button" onClick={handleSubmit}>
              اتمام التبرع
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InkindDonation;
