import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3'
import PageTitle from '../../components/pagetitle'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import img from '../../images/AboutTheCommittee.png'
import './style.css'

const CasePage = () => {
    return (
        <Fragment>
            <Navbar3 />
            <PageTitle img={img} pageTitle={'نبذة عن المؤسسة'} pagesub={'نبذة عن المؤسسة'} />
            <Newsletter />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default CasePage;
