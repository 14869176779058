import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import Hero from "../../components/hero";
import About from "../../components/about";
import CtaSection from "../../components/cta";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import abimg from "../../images/AboutTheCommittee.png";
import hero1 from "../../images/slider/slide1.png";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar />
      <Hero Hero={hero1} />
      {/* <Features /> */}
      <About AbImg={abimg} />
      {/* <EventSection />
      <TeamSection /> */}
      <CtaSection />
      {/* <Causes />
      <StartCampignProjectSection />
      <Partners />
      <CtaSection2 /> */}

      {/* <CounterSection /> */}

      {/* <Gallery /> */}
      {/* <BlogSection /> */}

      <Newsletter />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
