import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const PageTitle = (props) => {
  return (
    <div
      className="tp-breadcumb-area"
      style={{
        backgroundImage: `url(${props.img})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="tp-breadcumb-wrap">
              <h2>{props.pageTitle}</h2>
              <ul>
                <li>
                  <Link to="/">الصفحة الرئيسية</Link>
                </li>
                <li>
                  <span>{props.pagesub}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
