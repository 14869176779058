import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage";
import AboutPage from "../AboutPage";
import EventPage from "../EventPage";
import ContactPage from "../ContactPage";
import InkindDonationPage from "../InkindDonationPage";
import ElectronicWalletDonatePage from "../ElectronicWalletDonatePage";
import BankCardDonatePage from "../BankCardDonatePage";
import BankTransferDonatePage from "../BankTransferDonatePage";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/event" element={<EventPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/InkindDonationPage" element={<InkindDonationPage />} />
          <Route
            path="/ElectronicWalletDonatePage"
            element={<ElectronicWalletDonatePage />}
          />
          <Route path="/BankCardDonatePage" element={<BankCardDonatePage />} />
          <Route
            path="/BankTransferDonatePage"
            element={<BankTransferDonatePage />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
