import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.css";

class Hero extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: true,
      speed: 1200,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      fade: true,
    };

    return (
      <section className="hero hero-slider hero-style-1">
        <Slider {...settings}>
          <div className="slide">
            <div
              className="slide-inner"
              style={{ backgroundImage: `url(${this.props.Hero})` }}
            >
              <div className="container d-flex justify-content-center">
                <div className="col col-lg-6 slide-caption">
                  <div className="slide-subtitle">
                    <div className="ZakatCommittee">لجنة الزكاة</div>
                    <div className="MinistryOfHealth"> من وزارة الصحة</div>
                    <p>
                      {" "}
                      منصة إلكترونية مخصصة لتسهيل عملية التبرع ودعم الأعمال
                      الخيرية والمجتمعية. يهدف موقعنا إلى تقديم حلقة وصل بين
                      المتبرعين الكرام والجهات المستفيدة، بهدف تحقيق تأثير
                      إيجابي ومستدام في مختلف مجالات الحياة.
                    </p>
                  </div>
                  <div className="btns">
                    <Link
                      to="/InkindDonationPage"
                      className="theme-btn-s2 inKindDonateBtnSlider"
                    >
                      تبرع عيني{" "}
                    </Link>
                    <Link to="/event" className="theme-btn">
                      تبرع مادي
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>
    );
  }
}

export default Hero;
