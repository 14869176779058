import React from "react";
import bankMasrLogo from "../../images/bankMasr.png";
import bankAlahlyLogo from "../../images/bankAlahly.png";
import nsbLogo from "../../images/NSB.png";
import "./style.css";

const BankTransferDonate = () => {
  return (
    <div className="BankTransferDonate">
      <div className="donation-heading">التحويل البنكي</div>
      <div className="titleUnderLine underLineTitle"></div>
      <div className="BankTransferDonateContent">
        <span>
          يمكنك التبرع من خلال التحويلات البنكية بكل سهولة عن طريق الحسابات
          البنكية الخاصة بنا
        </span>
      </div>
      <div className="bankDataMainContainer">
        <div className="bankInfo">
          <span>بنك مصر</span>
          {/* <div>
            <span>اسم الفرع: </span>{" "}
            <div>فرع جراند مول ( المعادي الجديدة )</div>
          </div> */}
          <div>
            <span> رقم الحساب الحالي: </span> <div>1400001000004958</div>
          </div>
          <div>
            <span>رقم IBAN: </span> <div>EG910002014001400001000004958</div>
          </div>
        </div>
        <img src={bankMasrLogo} alt="" />
      </div>
      <div className="bankDataMainContainer">
        <div className="bankInfo">
          <span>البنك الأهلى المصرى</span>

          <div>
            <span> رقم الحساب الحالي: </span> <div>1469551697983300010</div>
          </div>
          <div>
            <span>رقم IBAN: </span> <div>EG080003014695516984400000130</div>
          </div>
        </div>
        <img src={bankAlahlyLogo} alt="" />
      </div>
      <div className="bankDataMainContainer">
        <div className="bankInfo">
          <span>بنك ناصر الاجتماعي</span>

          <div>
            <span> رقم الحساب الحالي: </span> <div>4958</div>
          </div>
          <div>
            <span>رقم IBAN: </span> <div>-</div>
          </div>
        </div>

        <img src={nsbLogo} width="137px" height="116px" alt="" />


      </div>
    </div>
  );
};

export default BankTransferDonate;
