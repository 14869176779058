import React from "react";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";
import "./style.css";

const Header = (props) => {

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className={`middle-header ${props.ms2}`}>
      <div className="header-style-3">
        <div className="header-content">
          {/* <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-4 col-4 d-flex align-items-center">
              <div className="logoMsajid">
                <Link onClick={ClickHandler} to="/home" title="">
                  <img src={Logo} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6 d-lg-block d-none">
              <nav>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/home" title="">
                      الصفحة الرئيسية
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/about" title="">
                      نبذة عن المؤسسة
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/event" title="">
                      شارك معنا
                      <span className="arrow">&#9662;</span>
                    </Link>
                    <ul>
                      <li>
                        <Link onClick={ClickHandler} to="/event" title="">
                          تبرع مادي
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          to="/InkindDonationPage"
                          title=""
                        >
                          تبرع عيني
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link onClick={ClickHandler} to="/contact" title="">
                      تواصل معنا
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              <div className="contact-info">
                <Link className="FinancialDonation" to="/InkindDonationPage">
                  تبرع عيني
                </Link>
                <Link className="In-kindDonationBtn" to="/event">
                  تبرع مادي
                </Link>
              </div>
            </div>
            <div className="col-md-1 col-sm-2 col-2">
              <MobileMenu />
            </div>
          </div> */}
          <div className="responsiveNavBar">
            <div className="d-flex align-items-center">
              <div className="logoMsajid">
                <Link onClick={ClickHandler} to="/home" title="">
                  <img src={Logo} alt="" />
                </Link>
              </div>
            </div>
            <div className="d-lg-block d-none">
              <nav>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/home" title="">
                      الصفحة الرئيسية
                    </Link>
                  </li>
                  <li>

                    <a onClick={ClickHandler} smooth href="#aboutUsSection" >نبذة عن المؤسسة</a>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/event" title="">
                      شارك معنا
                      <span className="arrow">&#9662;</span>
                    </Link>
                    <ul>
                      <li>
                        <Link onClick={ClickHandler} to="/event" title="">
                          تبرع مادي
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          to="/InkindDonationPage"
                          title=""
                        >
                          تبرع عيني
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link onClick={ClickHandler} to="/contact" title="">
                      تواصل معنا
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="d-lg-block d-none">
              <div className="contact-info">
                <Link className="FinancialDonation" to="/InkindDonationPage">
                  تبرع عيني
                </Link>
                <Link className="In-kindDonationBtn" to="/event">
                  تبرع مادي
                </Link>
              </div>
            </div>
            <div>
              <MobileMenu />
            </div>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
