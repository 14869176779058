import React, { useState, createContext } from "react";

export const Context = createContext();

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") ? true : false
  );

  const [openSignUpPopUp, setOpenSignUpPopUp] = useState(false);
  const [openCertificatePopUp, setCertificatePopUp] = useState(false);
  const [openDonateDeceasedPopUp, setDonateDeceasedPopUp] = useState(false);
  const [openConfirmChangePhoneNumberPopUp, setConfirmChangePhoneNumberPopUp] = useState(false);
  const [openChangePasswordPopUp, setChangePasswordPopUp] = useState(false);
  const [openDeceasedDetailesPopUp, setDeceasedDetailesPopUp] = useState(false);
  const [openGiftDetailesPopUp, setGiftDetailesPopUp] = useState(false);
  const [openShareDonateDetailesPopUp, setShareDonateDetailesPopUp] = useState(false);
  const [openAddAddressPopUp, setAddAddressPopUp] = useState(false);
  const [openAddressDetailsPopUp, setAddressDetailsPopUp] = useState(false);
  const [openDonationCertificatePopUp, setDonationCertificatePopUp] = useState(false);
  const [reload, setReload] = useState(false);

  let state = {
    openSignUpPopUp,
    setOpenSignUpPopUp,
    isLoggedIn,
    setIsLoggedIn,
    openCertificatePopUp,
    setCertificatePopUp,
    openDonateDeceasedPopUp,
    setDonateDeceasedPopUp,
    openConfirmChangePhoneNumberPopUp,
    setConfirmChangePhoneNumberPopUp,
    openChangePasswordPopUp,
    setChangePasswordPopUp,
    openDeceasedDetailesPopUp,
    setDeceasedDetailesPopUp,
    openGiftDetailesPopUp,
    setGiftDetailesPopUp,
    openShareDonateDetailesPopUp,
    setShareDonateDetailesPopUp,
    openAddAddressPopUp,
    setAddAddressPopUp,
    openAddressDetailsPopUp,
    setAddressDetailsPopUp,
    openDonationCertificatePopUp,
    setDonationCertificatePopUp,
    reload,
    setReload
  };

  return <Context.Provider value={state}>{props.children}</Context.Provider>;
};
