import React, { useEffect, useState } from "react";
import "./style.css";

const BankCardDonate = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [donateValue, setDonateValue] = useState("");

  const handleSubmit = () => {};

  return (
    <div className="BankCardDonate">
      <div className="donation-heading">بطاقة بنكية</div>
      <div className="titleUnderLine underLineTitle"></div>
      <div className="BankCardDonateContent">
        <span>
          يمكنك التبرع بأى من البطاقات البنكية فيزا, ماستر كارد, الخ..
        </span>
      </div>
      <div className="donatecontainer">
        <div className="content">
          <div className="donation-content">
            <div className="donateForm">
              <input
                className="other"
                type="text"
                placeholder="اسم المتبرع"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                className="other"
                type="text"
                placeholder="رقم الهاتف"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <input
                className="other"
                type="number"
                placeholder="قيمة التبرع"
                value={donateValue}
                onChange={(e) => setDonateValue(e.target.value)}
              />
            </div>
            <button className="donate-button" onClick={handleSubmit}>
              اتمام التبرع
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankCardDonate;
