import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3'
import PageTitle from '../../components/pagetitle'
import Contactpage from '../../components/Contactpage'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Newsletter from '../../components/Newsletter'
import img from '../../images/contactUsCover.png'


const ContactPage = () => {
    return (
        <Fragment>
            <Navbar3 />
            <PageTitle img={img} pageTitle={'تواصل معنا'} pagesub={'تواصل معنا'} />
            <Contactpage />
            <Newsletter />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default ContactPage;

