import React, { Component } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'
import './style.css';

const menus = [
    {
        id: 1,
        title: 'الصفحة الرئيسية',
        link: '/home'
    },

    {
        id: 2,
        title: 'نبذة عن المؤسسة',
        link: '#aboutUsSection',
    },
    {
        id: 4,
        title: 'شارك معنا',
        link: '/event',
        submenu: [
            {
                id: 47,
                title: 'تبرع مادي',
                link: '/event'
            },
            {
                id: 48,
                title: 'تبرع عيني',
                link: '/InkindDonationPage'
            }
        ]
    },
    {
        id: 88,
        title: 'تواصل معنا',
        link: '/contact',
    }


]


export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        const ClickHandler = () => {
            window.scrollTo(10, 0);
        }

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="closeSideMenuBtn" onClick={this.menuHandler}>X</div>

                    <ul className="responsivemenu">
                        {menus.map(item => {
                            return (
                                <li key={item.id}>
                                    {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                                        {item.title}
                                        {item.submenu ? <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}
                                    </p> : <a onClick={ClickHandler} href={item.link} >{item.title}</a>

                                    }
                                    {item.submenu ?
                                        <Collapse isOpen={item.id === isOpen}>
                                            <Card>
                                                <CardBody>
                                                    <ul>
                                                        {item.submenu.map(submenu => (
                                                            <li key={submenu.id}><a className="active" onClick={ClickHandler} href={submenu.link} >{submenu.title}</a></li>
                                                        ))}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                        : ''}
                                </li>
                            )
                        })}
                    </ul>
                    <div>
                        <div className="contact-info">
                            <Link className="FinancialDonationSideMenu" to="/InkindDonationPage">
                                تبرع عيني
                            </Link>
                            <Link className="In-kindDonationBtn" to="/event">
                                تبرع مادي
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>
            </div>
        )
    }
}
