import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../images/footerLogo.png";
import "./style.css";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="tp-ne-footer">
      <div className="tp-site-footer">
        <div className="tp-upper-footer">
          <div className="container">
            <div className="widget about-widget">
              <div className="widget-title footerLogoContainer">
                <img src={footerLogo} alt="" />
                <div className="d-flex flex-column">
                  <div className="ZakatCommitteeFooterStyle">
                    Zakat Committee
                  </div>
                  <div className="ZakatCommitteeDescFooterStyle">
                    لجنة الزكاه من وزارة الصحة المصرية
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <div className="parallax-wrap">
                    <div className="parallax-element">
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-google"></i>
                      </Link>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="parallax-wrap">
                    <div className="parallax-element">
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-instagram"></i>
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="parallax-wrap">
                    <div className="parallax-element">
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="parallax-wrap">
                    <div className="parallax-element">
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-facebook"></i>
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tp-lower-footer">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <p className="copyright">
                  © 2023 Zakat Committee. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
