import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const CtaSection = (props) => {
  return (
    <div id="joinUsSection" className="tp-cta-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="tp-cta-text">
              <h2>كيفية المساهمة معنا</h2>
              <p>
                نحن ندعوكم للانضمام إلينا في رحلة العطاء والخير. من خلال
                تبرعاتكم السخية، يمكننا معًا تحقيق تغيير حقيقي ومستدام، وتحسين
                حياة الكثيرين. شكراً لكم على ثقتكم ودعمكم المستمر.
              </p>
              <div className="btns">
                <Link to="/InkindDonationPage" className="theme-btn-s2">
                  تبرع عيني
                </Link>
                <Link to="/event" className="theme-btn">
                  تبرع مادي
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
