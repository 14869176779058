import React from 'react';
import ContactForm from '../ContactFrom'
import './style.css'

const Contactpage = () => {

    return (
        <section className="contact-pg-contact-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="section-title-s3 section-title-s5">
                            <h2>للتواصل</h2>
                        </div>
                        <div className="contact-details">
                            <ul>
                                <li>
                                    <div className="icon">
                                        <i className="ti-location-pin"></i>
                                    </div>
                                    <h5>العنوان</h5>
                                    <p>قطاع الرعاية العلاجية وزارة الصحة والسكان الحي الحكومي العاصمة الإدارية الجديدة
                                    </p>
                                </li>
                                <li>
                                    <div className="icon">
                                        <i className="ti-mobile"></i>
                                    </div>
                                    <h5>الهاتف</h5>
                                    <p>********</p>
                                </li>
                                <li>
                                    <div className="icon">
                                        <i className="ti-email"></i>
                                    </div>
                                    <h5>البريد الألكترونى</h5>
                                    <p>*********@********.***</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="contact-form-area">
                            <div className="section-title-s3 section-title-s5">
                                <h2>للرسائل</h2>
                            </div>
                            <div className="contact-form">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1386.3057098310067!2d31.232497116285774!3d30.04442278337299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s30.0444%2C%2031.2357!2sDowntown%2C%20Cairo%2C%20Egypt!5e0!3m2!1sen!2seg!4v1622170034025!5m2!1sen!2seg"></iframe>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    )

}

export default Contactpage;
