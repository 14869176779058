import React from "react";
import JoinUs from "../../images/AboutTheCommittee.png";
import { Link } from "react-router-dom";
import "./style.css";

const About = (props) => {
  return (
    <div id="aboutUsSection" className="about-style-2">
      <div className="container donateNowcontainer">
        <div className="row">
          <div className="col-lg-6 col-sm-12 col-12">
            <div className="about-img">
              <img src={JoinUs} alt="" />
              {/* <VideoModal /> */}
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-12">
            <div className="about-content">
              <div className="section-title">
                <div className="donateNowSectionTitle">
                  <div className="aboutTheCommitteeTitle">
                    عن لجنة<span className="aboutTheCommittee">الزكاة</span>
                  </div>
                </div>
                <div className="titleUnderLine"></div>
              </div>
              <div className="aboutTheCommitteeContent">
                <p>
                  فى لجنة زكاة وزارة الصحة لإدارة الأزمات والكوارث هدفنا هو
                  تمكين الأفراد والمؤسسات من المشاركة الفعالة في العمل الخيري
                  بسهولة ويسر، من خلال تقديم مجموعة متنوعة من الخيارات للتبرع
                  ودعم المشاريع التنموية والإنسانية التي تلبي احتياجات المجتمع.
                  <br />
                  ساهموا الآن لنصنع فرقاً، ولنكن يد العون لمن يحتاجها.
                </p>
              </div>

              {/* <div className="btns">
                <div className="submitDonateContainer">
                  <Link to="/home" className="submitDonate">
                    اعرف أكثر
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
