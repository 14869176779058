import React, { Fragment, useEffect } from "react";
import Navbar3 from "../../components/Navbar3";
import PageTitle from "../../components/pagetitle";
import About from "../../components/about";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Newsletter from "../../components/Newsletter";
import img from "../../images/Inkind-DonationContentCover.png";
import InkindDonation from "../../components/InkindDonation/InkindDonation";

const InkindDonationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Navbar3 />
      <PageTitle img={img} pageTitle={"تبرع  عيني"} pagesub={"تبرع عيني"} />
      <InkindDonation />
      <Newsletter />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default InkindDonationPage;
