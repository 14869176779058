import React, { Fragment, useEffect } from "react";
import Navbar3 from "../../components/Navbar3";
import PageTitle from "../../components/pagetitle";
import About from "../../components/about";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Newsletter from "../../components/Newsletter";
import img from "../../images/BankTransferCover.png";
import BankTransferdDonate from "../../components/BankTransferDonate";

const BankTransferDonatePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Navbar3 />
      <PageTitle
        img={img}
        pageTitle={"التحويل البنكي"}
        pagesub={"التحويل البنكي"}
      />
      <BankTransferdDonate />
      <Newsletter />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BankTransferDonatePage;
